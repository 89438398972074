<template>
  <div class="bg-white">
    <main class="isolate">
      <TopHeader />
      <HeroSection />

      <div class="relative -z-10 mt-32 px-6 lg:px-8">
        <div
          class="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-1/2 transform-gpu justify-center overflow-hidden blur-3xl sm:bottom-0 sm:right-[calc(50%-6rem)] sm:top-auto sm:translate-y-0 sm:transform-gpu sm:justify-end"
          aria-hidden="true"
        >
          <div
            class="aspect-[1108/632] w-[69.25rem] flex-none bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-25"
            style="
              clip-path: polygon(
                73.6% 48.6%,
                91.7% 88.5%,
                100% 53.9%,
                97.4% 18.1%,
                92.5% 15.4%,
                75.7% 36.3%,
                55.3% 52.8%,
                46.5% 50.9%,
                45% 37.4%,
                50.3% 13.1%,
                21.3% 36.2%,
                0.1% 0.1%,
                5.4% 49.1%,
                21.4% 36.4%,
                58.9% 100%,
                73.6% 48.6%
              );
            "
          ></div>
        </div>
      </div>
    </main>

    <!-- Footer -->
    <div class="mx-auto mt-32 max-w-7xl px-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        class="relative border-t border-gray-900/10 py-24 sm:mt-56 sm:py-32"
      >
        <h2 id="footer-heading" class="sr-only">Footer</h2>
        <div class="xl:grid xl:grid-cols-1 xl:gap-8">
          <div
            class="text-center mt-16 grid grid-cols-1 gap-8 xl:col-span-2 xl:mt-0"
          >
            <p>
              © 2023-{{ new Date().getFullYear() }} Idempotence℠. All rights
              reserved.
            </p>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import HeroSection from "./sections/HeroSection.vue";
import TopHeader from "./TopHeader.vue";

import fullLogo from "../../../branding-assets/rasters/full-logo.png";

export default {
  name: "LandingPage",
  props: {
    slug: String,
  },
  components: {
    HeroSection,
    TopHeader,
  },
  data() {
    return {
      fullLogo,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
