<template>
    <header class="absolute inset-x-0 top-0 z-50">
        <nav class="flex items-center justify-between p-6 lg:px-8" aria-label="Global">
          <TopLogo />
          <div class="hidden lg:flex lg:flex-1 lg:justify-end"></div>
        </nav>
      </header>
</template>

<script>
import TopLogo from './header/TopLogo.vue'

export default {
  name: 'TopHeader',
  data() {
    return {
     open: false,
    };
  },
  methods: {
    toggleOpen() {
      this.open = !this.open
    },
  },
  components: {
    TopLogo
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>