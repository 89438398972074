<template>
    <div class="flex lg:flex-1">
        <a href="/" class="-m-1.5 p-1.5">
          <span class="sr-only">Idempotence</span>
          <img class="lg:hidden h-8 w-auto" :src=smallLogo alt="">
          <img class="hidden lg:block h-8 w-auto" :src=fullLogo alt="" />
        </a>
    </div>
</template>

<script>
import smallLogo from '../../../../branding-assets/rasters/small-logo.png'
import fullLogo from '../../../../branding-assets/rasters/full-logo.png'

export default {
  name: 'TopLogo',
  data() {
    return {
      smallLogo,
      fullLogo
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>