<template>
  <LandingPage msg="A business continuity, quality assurance and policy compliance platform."/>
</template>

<script>
import LandingPage from './components/LandingPage.vue'

export default {
  name: 'App',
  components: {
    LandingPage
  }
}
</script>

<style>

</style>
